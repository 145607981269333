import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import type { BaseOutput } from '@dextools/core';
import { ApiService, PathId } from '@dextools/core';
import type { ApiConfig, ApiCategoriesConfig, ApiCategoryConfig, ConfigKeys } from '@dextools/blockchains';
import { ChainUtil } from '@dextools/blockchains';

@Injectable({
  providedIn: 'root',
})
export class ConfigPagesApiService {
  public constructor(private readonly _apiService: ApiService) {}

  /**
   * Returns the config of the given key.
   *
   * IMPORTANT:
   * Each feature will have a key, which is the one to be used in the endpoint.
   * In case the endpoint returns no data, means that the feature is enabled for all the app/chains.
   * That way back doesn't have to update the key whenever the feature has been released for all the chains.
   *
   * @param key - Feature key.
   * @returns The feature config.
   */
  public getPageConfig$(key: string): Observable<ApiConfig> {
    const params = `?key=${key}`;
    return this._apiService.get<BaseOutput<ApiConfig>>(PathId.SHARED, `/config${params}`).pipe(
      map((response) => {
        delete response.data['key'];
        const featuresConfig = response.data;
        const finalFeaturesConfig: ApiConfig = {};

        for (const feature in featuresConfig) {
          if (!this._isConfigKeysObject(featuresConfig[feature])) {
            finalFeaturesConfig[feature] = featuresConfig[feature];
          } else {
            finalFeaturesConfig[feature] = finalFeaturesConfig[feature] ?? {}; // initialize object if needed
            const apiFeature = featuresConfig[feature] as ConfigKeys;
            const finalFeature = finalFeaturesConfig[feature] as ConfigKeys;

            if (apiFeature['chains']) {
              finalFeature['chains'] = apiFeature['chains'];

              for (const chain of finalFeature['chains'] as unknown as { slug: string }[]) {
                chain.slug = ChainUtil.replaceLegacyChain(chain.slug);
              }
            } else {
              for (const chain in apiFeature) {
                const finalChain = ChainUtil.replaceLegacyChain(chain);
                finalFeature[finalChain] = apiFeature[chain];
              }
            }
          }
        }

        return finalFeaturesConfig;
      }),
    );
  }

  public getCategoriesConfig$(key: string): Observable<ApiCategoryConfig[]> {
    const params = `?key=${key}`;
    return this._apiService.get<ApiCategoriesConfig>(PathId.SHARED, `/config${params}`).pipe(
      map((response) => {
        return response.data.categories;
      }),
    );
  }

  private _isConfigKeysObject(value: unknown): value is ConfigKeys {
    return typeof value === 'object' && value !== null;
  }
}

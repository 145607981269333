import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, debounceTime, of, Subject, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs';
import type { ConfigKeys, ApiConfig, ApiCategoryConfig, ApiCategory, Chain, BlockchainData } from '@dextools/blockchains';
import { ConfigPagesApiService } from './config-pages-api.service';
import { EXCHANGE_LOGO_REMOTE_BASE_URL, ChainUtil } from '@dextools/blockchains';
import { HOUR_IN_MILLIS, LocalStorageUtil } from '@dextools/utils';

const MAX_TIME = 6 * HOUR_IN_MILLIS; // 6 hours in milliseconds

export const CATEGORIES_CONFIG_KEY = 'categories-config';
export const CATEGORIES_TIMESTAMP_CONFIG_KEY = 'categories-config-timestamp';

@Injectable({
  providedIn: 'root',
})
export class ConfigPagesService {
  private _configPages: ApiConfig = {};
  private _categories: ApiCategoryConfig[] = [];
  private readonly _fetchCategoriesSubject$ = new Subject();
  private readonly _categoriesConfigLoaded$ = new BehaviorSubject<void>(undefined);

  public constructor(private readonly _configPagesApiService: ConfigPagesApiService) {
    this._fetchCategoriesSubject$
      .pipe(
        debounceTime(1_000),
        switchMap(() => this.fetchCategoriesConfig$(true)),
      )
      .subscribe();
  }

  public fetchConfigFromPage$(page: string): Observable<ApiConfig> {
    return this._configPagesApiService.getPageConfig$(page).pipe(tap((data) => (this._configPages = data)));
  }

  /**
   * Fetch categories config.
   * If isMissingCategory is true, it will fetch the categories config from the API.
   * If isMissingCategory is false, it will fetch the categories config from the local storage.
   *
   * Also, if the time elapsed is greater than MAX_TIME, it will fetch the categories config from the API.
   *
   * @param isMissingCategory - If  mapped category is missing in the config .
   *
   * @returns Observable containing the categories' config.
   */
  public fetchCategoriesConfig$(isMissingCategory = false): Observable<ApiCategoryConfig[]> {
    const categoryConfig = LocalStorageUtil.get<ApiCategoryConfig[]>(CATEGORIES_CONFIG_KEY);
    const timestamp = LocalStorageUtil.getString(CATEGORIES_TIMESTAMP_CONFIG_KEY);
    const currentTime = Date.now();

    if (categoryConfig && timestamp && !isMissingCategory) {
      const timeElapsed = currentTime - Number.parseInt(timestamp, 10);
      if (timeElapsed < MAX_TIME) {
        this._categories = categoryConfig;
        this._categoriesConfigLoaded$.next();
        return of(this._categories);
      }
    }

    return this._configPagesApiService.getCategoriesConfig$('token-categories').pipe(
      tap((data) => {
        LocalStorageUtil.setString(CATEGORIES_CONFIG_KEY, JSON.stringify(this._mapCategories(data)));
        LocalStorageUtil.setString(CATEGORIES_TIMESTAMP_CONFIG_KEY, currentTime.toString());
        this._categoriesConfigLoaded$.next();
        return (this._categories = this._mapCategories(data));
      }),
      catchError(() => {
        this._categoriesConfigLoaded$.next();
        return of([]);
      }),
    );
  }

  /**
   * Maps the categories adding the images' URLs.
   *
   * @param categoriesConfig - The categories' config.
   *
   * @returns The mapped categories
   */
  private _mapCategories(categoriesConfig: ApiCategoryConfig[]): ApiCategory[] {
    const categoryNames: ApiCategory[] = [];

    for (const categoryConfig of categoriesConfig) {
      categoryNames.push({
        id: categoryConfig.id,
        name: categoryConfig.name,
        smallImage: `${EXCHANGE_LOGO_REMOTE_BASE_URL}/resources/categories/${categoryConfig.smallImage}`,
        mediumImage: `${EXCHANGE_LOGO_REMOTE_BASE_URL}/resources/categories/${categoryConfig.mediumImage}`,
      });
    }

    return categoryNames;
  }

  public isFeatureAvailableForChain(chain: Chain, feature: string): boolean {
    return !!(
      Object.keys(this._configPages).length === 0 ||
      this._configPages[feature] === true ||
      (this._configPages[feature] && (this._configPages[feature] as ConfigKeys)[chain])
    );
  }

  /**
   * Get chains by feature.
   *
   * @param feature - feature name
   *
   * @returns chains available for the feature
   */
  public getChainsByFeature(feature: string): Chain[] {
    if (this._configPages[feature]) {
      return ((this._configPages[feature] as ConfigKeys)['chains'] as unknown as BlockchainData[]).map((chain) =>
        ChainUtil.replaceLegacyChain(chain.slug),
      ) as Chain[];
    }
    return [];
  }

  public triggerFetchCategories(): void {
    this._fetchCategoriesSubject$.next(null);
  }

  public getCategoriesConfigLoaded$(): Observable<void> {
    return this._categoriesConfigLoaded$.asObservable();
  }

  public getConfigPages(): ApiConfig {
    return this._configPages;
  }

  public getCategoriesConfig(): ApiCategoryConfig[] {
    return this._categories;
  }
}
